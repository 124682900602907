import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Group, Line, Circle, Text } from 'react-konva';

/**
 * SegmentSelectionTool Component
 * 
 * Инструмент для выделения произвольных сегментов (участков) линий и полилиний.
 * Позволяет кликать и перетаскивать вдоль элемента для создания сегмента.
 */
const SegmentSelectionTool = ({
  element,
  scale,
  onSegmentSelect,
  currentlySelectedSegment,
  color = '#1976d2'
}) => {
  // Состояние для хранения точек выделения
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [hoverPoint, setHoverPoint] = useState(null);
  const selectionRef = useRef(null);

  // Находим ближайшую точку на элементе к заданным координатам
  const findClosestPointOnElement = (x, y) => {
    if (!element || !element.points || element.points.length < 2) return null;

    let closestPoint = null;
    let minDistance = Infinity;
    let segmentIndex = -1;
    let pointPosition = 0; // Процентная позиция на элементе (0-100%)
    let totalLength = 0;
    let lengthToPoint = 0;

    // Расчет общей длины элемента
    for (let i = 0; i < element.points.length - 1; i++) {
      const p1 = element.points[i];
      const p2 = element.points[i + 1];
      const segLength = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
      totalLength += segLength;
    }

    // Находим ближайшую точку
    for (let i = 0; i < element.points.length - 1; i++) {
      const p1 = element.points[i];
      const p2 = element.points[i + 1];
      
      // Расчет расстояний и нахождение точки
      const result = findClosestPointOnSegment(x, y, p1.x, p1.y, p2.x, p2.y);
      
      if (result.distance < minDistance) {
        minDistance = result.distance;
        closestPoint = result.point;
        segmentIndex = i;
        
        // Расчет длины до этого сегмента
        let lengthToSegment = 0;
        for (let j = 0; j < i; j++) {
          const segP1 = element.points[j];
          const segP2 = element.points[j + 1];
          lengthToSegment += Math.sqrt(Math.pow(segP2.x - segP1.x, 2) + Math.pow(segP2.y - segP1.y, 2));
        }
        
        // Расчет позиции внутри сегмента
        const segLength = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
        const posOnSegment = Math.sqrt(Math.pow(closestPoint.x - p1.x, 2) + Math.pow(closestPoint.y - p1.y, 2));
        
        // Общая длина до точки
        lengthToPoint = lengthToSegment + posOnSegment;
        pointPosition = (lengthToPoint / totalLength) * 100;
      }
    }

    // Возвращаем результат, только если точка достаточно близка к элементу
    // Увеличиваем пороговое значение для более удобного выделения
    if (closestPoint && minDistance <= 20 / scale) {
      return {
        point: closestPoint,
        segmentIndex,
        position: pointPosition,
        distance: minDistance,
        length: lengthToPoint,
        totalLength
      };
    }

    return null;
  };

  // Находим ближайшую точку на сегменте линии
  const findClosestPointOnSegment = (x, y, x1, y1, x2, y2) => {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;

    if (len_sq !== 0) {
      param = dot / len_sq;
    }

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    const dx = x - xx;
    const dy = y - yy;
    const distance = Math.sqrt(dx * dx + dy * dy);

    return {
      distance,
      point: { x: xx, y: yy }
    };
  };

  // Обработчик движения мыши по элементу
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    if (!stage) return;
    
    const pointerPos = stage.getPointerPosition();
    if (!pointerPos) return;
    
    // Улучшенное преобразование координат с использованием абсолютной трансформации
    const transform = e.target.getAbsoluteTransform().copy().invert();
    const transformedPos = transform.point(pointerPos);
    
    // Находим ближайшую точку на элементе
    const closest = findClosestPointOnElement(transformedPos.x, transformedPos.y);
    
    if (closest) {
      setHoverPoint(closest);
      document.body.style.cursor = 'crosshair';
      
      // Если происходит перетаскивание, обновляем конечную точку
      if (isDragging && selectionStart) {
        setSelectionEnd(closest);
      }
    } else {
      setHoverPoint(null);
      document.body.style.cursor = 'default';
    }
  };
  
  // Обработчик нажатия мыши для начала выделения сегмента
  const handleMouseDown = (e) => {
    if (!hoverPoint) return;
    
    setSelectionStart(hoverPoint);
    setSelectionEnd(null);
    setIsDragging(true);
  };
  
  // Обработчик отпускания мыши для завершения выделения сегмента
  const handleMouseUp = () => {
    if (isDragging && selectionStart && selectionEnd) {
      // Убеждаемся, что начало идет перед концом (меньший процент)
      const segment = {
        elementId: element.id,
        startPosition: Math.min(selectionStart.position, selectionEnd.position) / 100,
        endPosition: Math.max(selectionStart.position, selectionEnd.position) / 100,
        startLength: Math.min(selectionStart.length, selectionEnd.length),
        endLength: Math.max(selectionStart.length, selectionEnd.length),
        totalLength: selectionStart.totalLength,
        status: 'not_started',
        completion: 0
      };
      
      onSegmentSelect(segment);
    }
    
    setIsDragging(false);
  };
  
  // Расчет точек для отрисовки выделенного сегмента
  const getSelectedSegmentPoints = () => {
    if (!selectionStart) return [];
    
    const start = selectionStart;
    const end = selectionEnd || selectionStart;
    
    // Если точки находятся на одном сегменте линии
    if (start.segmentIndex === end.segmentIndex) {
      return [start.point.x, start.point.y, end.point.x, end.point.y];
    }
    
    // Если точки находятся на разных сегментах, нужно включить все точки между ними
    const points = [start.point.x, start.point.y];
    
    // Определяем направление
    const isForward = start.segmentIndex <= end.segmentIndex;
    
    if (isForward) {
      // Добавляем все точки между началом и концом
      for (let i = start.segmentIndex + 1; i <= end.segmentIndex; i++) {
        points.push(element.points[i].x, element.points[i].y);
      }
    } else {
      // Добавляем все точки между концом и началом (в обратном порядке)
      for (let i = start.segmentIndex; i > end.segmentIndex; i--) {
        points.push(element.points[i].x, element.points[i].y);
      }
    }
    
    // Добавляем конечную точку
    points.push(end.point.x, end.point.y);
    
    return points;
  };
  
  // Получение точек для сегмента по позициям (0-1)
  const getSegmentPointsFromPositions = (startPos, endPos) => {
    const points = [];
    let cumulativeLength = 0;
    let totalLength = 0;
    
    // Расчет общей длины элемента
    for (let i = 0; i < element.points.length - 1; i++) {
      const p1 = element.points[i];
      const p2 = element.points[i + 1];
      const segLength = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
      totalLength += segLength;
    }
    
    const startLength = totalLength * startPos;
    const endLength = totalLength * endPos;
    
    // Находим начальную точку
    for (let i = 0; i < element.points.length - 1; i++) {
      const p1 = element.points[i];
      const p2 = element.points[i + 1];
      const segLength = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
      
      // Если начальная точка находится в этом сегменте
      if (cumulativeLength <= startLength && startLength <= cumulativeLength + segLength) {
        const ratio = (startLength - cumulativeLength) / segLength;
        const x = p1.x + ratio * (p2.x - p1.x);
        const y = p1.y + ratio * (p2.y - p1.y);
        points.push(x, y);
      }
      
      // Если сегмент полностью входит в выделение
      if (startLength <= cumulativeLength && cumulativeLength + segLength <= endLength) {
        if (points.length === 0) {
          // Если мы еще не добавили начальную точку
          points.push(p1.x, p1.y);
        }
        points.push(p2.x, p2.y);
      }
      
      // Если конечная точка находится в этом сегменте
      if (cumulativeLength <= endLength && endLength <= cumulativeLength + segLength) {
        const ratio = (endLength - cumulativeLength) / segLength;
        const x = p1.x + ratio * (p2.x - p1.x);
        const y = p1.y + ratio * (p2.y - p1.y);
        if (points.length === 0) {
          // Если мы еще не добавили ни одной точки
          points.push(p1.x, p1.y);
        }
        points.push(x, y);
        break; // Мы достигли конечной точки
      }
      
      cumulativeLength += segLength;
    }
    
    return points;
  };
  
  // Очистка курсора при размонтировании компонента
  useEffect(() => {
    return () => {
      document.body.style.cursor = 'default';
    };
  }, []);
  
  // Функция рендеринга
  return (
    <Group
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      listening={true}
      perfectDrawEnabled={false}
      hitStrokeWidth={20}
    >
      {/* Отображение индикатора наведения */}
      {hoverPoint && (
        <Circle
          x={hoverPoint.point.x}
          y={hoverPoint.point.y}
          radius={7 / scale} // Увеличиваем размер для лучшей видимости
          fill={color}
          opacity={0.7}
        />
      )}
      
      {/* Отображение начальной точки выделения */}
      {selectionStart && (
        <Circle
          x={selectionStart.point.x}
          y={selectionStart.point.y}
          radius={7 / scale}
          fill={color}
          stroke="#fff"
          strokeWidth={2 / scale}
        />
      )}
      
      {/* Отображение конечной точки выделения */}
      {selectionEnd && (
        <Circle
          x={selectionEnd.point.x}
          y={selectionEnd.point.y}
          radius={7 / scale}
          fill={color}
          stroke="#fff"
          strokeWidth={2 / scale}
        />
      )}
      
      {/* Отображение линии выделения */}
      {selectionStart && selectionEnd && (
        <Line
          points={getSelectedSegmentPoints()}
          stroke={color}
          strokeWidth={4 / scale}
          opacity={0.7}
          ref={selectionRef}
        />
      )}
      
      {/* Отображение измерений */}
      {selectionStart && selectionEnd && (
        <Text
          x={(selectionStart.point.x + selectionEnd.point.x) / 2}
          y={(selectionStart.point.y + selectionEnd.point.y) / 2 - 15 / scale}
          text={`${Math.abs(selectionEnd.length - selectionStart.length).toFixed(2)} units (${Math.abs(selectionEnd.position - selectionStart.position).toFixed(1)}%)`}
          fontSize={12 / scale}
          fill={color}
          align="center"
        />
      )}
      
      {/* Отображение текущего выбранного сегмента, если он указан */}
      {currentlySelectedSegment && 
       currentlySelectedSegment.elementId === element.id && 
       !isDragging && (
        <Group>
          {/* Отображение линии выбранного сегмента */}
          <Line
            points={getSegmentPointsFromPositions(
              currentlySelectedSegment.startPosition,
              currentlySelectedSegment.endPosition
            )}
            stroke={color}
            strokeWidth={4 / scale}
            opacity={0.5}
          />
          
          {/* Индикатор прогресса сегмента */}
          {currentlySelectedSegment.completion > 0 && (
            <Line
              points={getSegmentPointsFromPositions(
                currentlySelectedSegment.startPosition,
                currentlySelectedSegment.startPosition + 
                (currentlySelectedSegment.endPosition - currentlySelectedSegment.startPosition) * 
                (currentlySelectedSegment.completion / 100)
              )}
              stroke={'#00c853'} // Зеленый для завершенной части
              strokeWidth={4 / scale}
              opacity={0.7}
            />
          )}
        </Group>
      )}
    </Group>
  );
};

SegmentSelectionTool.propTypes = {
  element: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  onSegmentSelect: PropTypes.func.isRequired,
  currentlySelectedSegment: PropTypes.object,
  color: PropTypes.string
};

export default SegmentSelectionTool;
