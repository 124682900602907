import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  ZoomIn,
  ZoomOut,
  CropFree,
  Visibility,
  VisibilityOff,
  BrushOutlined,
  ContentCut
} from '@mui/icons-material';
import { Stage, Layer, Line, Circle, Group, Text, Ellipse, Arc, Rect } from 'react-konva';
import ElementRenderer from './ElementRenderer';
import SegmentSelectionTool from './SegmentSelectionTool';
import WorkAreaRenderer from './WorkAreaRenderer';

/**
 * DxfViewport Component
 * 
 * This component is responsible for rendering the DXF drawing using Konva,
 * and handling viewport interactions like zooming, panning, and element selection.
 */
const DxfViewport = ({
  // Drawing data
  lightweightData,
  
  // Viewport state
  viewportSize,
  scale,
  position,
  isPanning,
  
  // Selection state
  selectionMode,
  selectedElement,
  selectedWorkArea,
  selectedSegments,
  customSelectedSegment,
  customSegments,
  getCustomSegmentsForElement,
  
  // Display settings
  layersVisible,
  displaySettings,
  filteredWorkAreas,
  statusColors,
  
  // Events
  onHandleWheel,
  onHandleMouseDown,
  onHandleMouseMove,
  onHandleMouseUp,
  onElementClick,
  onSegmentClick,
  onCustomSegmentSelect,
  onWorkAreaClick,
  isSegmentSelected,
  isCustomSegmentSelected,
  
  // Controls
  onZoomIn,
  onZoomOut,
  onFitDrawingToViewport,
  onToggleNonWorkAreaElements
}) => {
  // Локальное состояние для активного сегментирующего элемента
  const [activeSegmentingElement, setActiveSegmentingElement] = useState(null);
  
  // Check if an element is in the current viewport
  const isElementVisible = useCallback((element) => {
    // Define viewport boundaries in model coordinates
    const vpMinX = -position.x / scale;
    const vpMinY = -position.y / scale;
    const vpMaxX = (viewportSize.width - position.x) / scale;
    const vpMaxY = (viewportSize.height - position.y) / scale;

    // Add padding to viewport
    const padding = 200; // Units in model space

    // Check element points
    if (element.points && element.points.length > 0) {
      return element.points.some(p =>
        p.x >= vpMinX - padding && p.x <= vpMaxX + padding &&
        p.y >= vpMinY - padding && p.y <= vpMaxY + padding
      );
    }

    // Check center for circles
    if (element.center) {
      const radius = element.radius || 0;
      return element.center.x + radius >= vpMinX - padding &&
        element.center.x - radius <= vpMaxX + padding &&
        element.center.y + radius >= vpMinY - padding &&
        element.center.y - radius <= vpMaxY + padding;
    }

    // Check position for other elements
    if (element.position) {
      return element.position.x >= vpMinX - padding &&
        element.position.x <= vpMaxX + padding &&
        element.position.y >= vpMinY - padding &&
        element.position.y <= vpMaxY + padding;
    }

    return true; // If no coordinates to check, consider it visible
  }, [position, scale, viewportSize]);

  // Get visible elements based on selected work areas and viewport
  const visibleElements = useMemo(() => {
    if (!lightweightData || !lightweightData.elements) return [];

    // Filter by layer visibility and viewport
    return lightweightData.elements.filter(element => {
      // Check if element's layer is visible
      const elementLayer = element.layer || '0';
      if (!layersVisible[elementLayer]) return false;

      // Check if element is in a work area or if we're showing non-work-area elements
      if (!element.workAreaId && !displaySettings.showNonWorkAreaElements) return false;
      
      // Check if element is in viewport
      return isElementVisible(element);
    });
  }, [lightweightData, layersVisible, isElementVisible, displaySettings.showNonWorkAreaElements]);

  // Get color for an element based on work area status
  const getElementColor = (element) => {
    if (!element) return '#AAAAAA';
    
    // Use status color for element with work area
    if (element.workAreaId) {
      // Get color based on status
      return statusColors[element.status || 'not_started'] || '#AAAAAA';
    }
    
    // For elements without work area, use grey with reduced opacity
    return '#AAAAAA';
  };
  
  // Get color for a segment based on its status
  const getSegmentColor = (segment) => {
    // If segment has its own status, use it
    if (segment && segment.status) {
      return statusColors[segment.status] || '#AAAAAA';
    }
    
    // Otherwise, use default color
    return '#1976d2';
  };
  
  // Get color for a work area based on status
  const getWorkAreaColor = (workArea) => {
    if (!workArea) return statusColors.not_started;
    return statusColors[workArea.status] || statusColors.not_started;
  };

  // Обработчик клика по элементу для режима сегментирования
  const handleElementClickForSegmentation = (element) => {
    if (selectionMode === 'segment' && !isPanning) {
      console.log("Activating segmentation for element:", element.id);
      setActiveSegmentingElement(element);
    }
  };
  
  // Обработка выделения произвольного сегмента
  const handleCustomSegmentSelect = (segment) => {
    if (activeSegmentingElement && selectionMode === 'segment' && !isPanning) {
      console.log("Custom segment selected:", segment);
      // Вызов родительского обработчика с передачей выбранного элемента и сегмента
      onCustomSegmentSelect(activeSegmentingElement, segment);
    }
  };
  
  // Рендер рабочей области
  const renderWorkArea = (workArea) => {
    if (!workArea || !displaySettings.showWorkAreaBoundaries) return null;
    
    // Получаем элементы данной рабочей области
    const workAreaElements = visibleElements.filter(el => el.workAreaId === workArea.id);
    
    const isSelected = selectedWorkArea && selectedWorkArea.id === workArea.id;
    const color = getWorkAreaColor(workArea);
    
    return (
      <WorkAreaRenderer
        key={`work-area-${workArea.id}`}
        workArea={workArea}
        elements={workAreaElements}
        scale={scale}
        color={color}
        isSelected={isSelected}
        onWorkAreaClick={() => onWorkAreaClick(workArea)}
        showLabel={true}
      />
    );
  };
  
  // Рендер элемента
  const renderElement = (element) => {
    // Базовые свойства элемента
    const isSelected = selectedElement && selectedElement.id === element.id;
    const inWorkArea = !!element.workAreaId;
    const opacity = inWorkArea ? 1.0 : displaySettings.nonWorkAreaOpacity;
    const color = getElementColor(element);
    
    // Получаем пользовательские сегменты для этого элемента
    const elementCustomSegments = getCustomSegmentsForElement(element.id);
    
    // Проверяем, активен ли инструмент выделения сегментов для этого элемента
    const isSegmentingActive = selectionMode === 'segment' && 
                              activeSegmentingElement && 
                              activeSegmentingElement.id === element.id;
    
    // Рендерим элемент со всеми его сегментами
    return (
      <Group key={`element-${element.id}`}>
        <ElementRenderer
          entity={element}
          isSelected={isSelected}
          scale={scale}
          color={color}
          opacity={opacity}
          onElementClick={() => {
            onElementClick(element);
            // Если активен режим сегментирования, устанавливаем этот элемент
            // как активный для сегментирования
            if (selectionMode === 'segment') {
              handleElementClickForSegmentation(element);
            }
          }}
          customSegments={elementCustomSegments}
          isSegmentSelected={(segment) => isCustomSegmentSelected(element.id, segment.id)}
          onSegmentClick={(segment) => onCustomSegmentSelect(element, segment)}
          getSegmentColor={getSegmentColor}
          highlightMode={selectionMode === 'segment'}
        />
        
        {/* Рендер инструмента выделения сегментов только для активного элемента */}
        {selectionMode === 'segment' && (
          <SegmentSelectionTool
            element={element}
            scale={scale}
            onSegmentSelect={(segment) => handleCustomSegmentSelect(segment)}
            currentlySelectedSegment={
              customSelectedSegment && 
              customSelectedSegment.elementId === element.id ? 
              customSelectedSegment : null
            }
            color="#1976d2"
          />
        )}
      </Group>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        border: '1px solid #ddd',
        borderRadius: 1,
        overflow: 'hidden',
        bgcolor: '#f5f5f5',
        cursor: isPanning ? 'grab' : 
                selectionMode === 'element' ? 'cell' :
                selectionMode === 'segment' ? 'crosshair' : 'default',
        position: 'relative'
      }}
    >
      <Stage
        width={viewportSize.width}
        height={viewportSize.height}
        onWheel={onHandleWheel}
        onMouseDown={onHandleMouseDown}
        onMouseMove={onHandleMouseMove}
        onMouseUp={onHandleMouseUp}
        onMouseLeave={onHandleMouseUp}
        draggable={false}
      >
        <Layer>
          <Group x={position.x} y={position.y} scaleX={scale} scaleY={scale}>
            {/* Рендерим рабочие области */}
            {filteredWorkAreas.map(renderWorkArea)}
            
            {/* Рендерим видимые элементы */}
            {visibleElements.map(renderElement)}
          </Group>
        </Layer>
      </Stage>

      {/* Zoom controls */}
      <Box sx={{
        position: 'absolute',
        bottom: 12,
        right: 12,
        display: 'flex',
        bgcolor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 1,
        p: 0.5
      }}>
        <Tooltip title="Zoom In">
          <IconButton size="small" onClick={onZoomIn}>
            <ZoomIn fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
          <IconButton size="small" onClick={onZoomOut}>
            <ZoomOut fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Fit Drawing">
          <IconButton size="small" onClick={onFitDrawingToViewport}>
            <CropFree fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      
      {/* Display mode controls */}
      <Box sx={{
        position: 'absolute',
        top: 12,
        left: 12,
        display: 'flex',
        bgcolor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 1,
        p: 0.5
      }}>
        <Tooltip title={displaySettings.showNonWorkAreaElements ? 
          "Hide Elements Outside Work Areas" : 
          "Show All Elements"}>
          <IconButton 
            size="small" 
            onClick={onToggleNonWorkAreaElements}
            color={displaySettings.showNonWorkAreaElements ? "primary" : "default"}
          >
            {displaySettings.showNonWorkAreaElements ? 
              <Visibility fontSize="small" /> : 
              <VisibilityOff fontSize="small" />}
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Selection Mode: Element">
          <IconButton 
            size="small" 
            onClick={() => onElementClick(null)}
            color={selectionMode === 'element' ? "primary" : "default"}
          >
            <BrushOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Selection Mode: Segment">
          <IconButton 
            size="small" 
            onClick={() => onSegmentClick(null, null)}
            color={selectionMode === 'segment' ? "primary" : "default"}
            disabled={!displaySettings.enableSegmentSelection}
          >
            <ContentCut fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

DxfViewport.propTypes = {
  lightweightData: PropTypes.object,
  viewportSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  scale: PropTypes.number,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  isPanning: PropTypes.bool,
  selectionMode: PropTypes.string,
  selectedElement: PropTypes.object,
  selectedWorkArea: PropTypes.object,
  selectedSegments: PropTypes.array,
  customSelectedSegment: PropTypes.object,
  customSegments: PropTypes.object,
  getCustomSegmentsForElement: PropTypes.func,
  layersVisible: PropTypes.object,
  displaySettings: PropTypes.object,
  filteredWorkAreas: PropTypes.array,
  statusColors: PropTypes.object,
  onHandleWheel: PropTypes.func.isRequired,
  onHandleMouseDown: PropTypes.func.isRequired,
  onHandleMouseMove: PropTypes.func.isRequired,
  onHandleMouseUp: PropTypes.func.isRequired,
  onElementClick: PropTypes.func.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
  onCustomSegmentSelect: PropTypes.func,
  onWorkAreaClick: PropTypes.func.isRequired,
  isSegmentSelected: PropTypes.func.isRequired,
  isCustomSegmentSelected: PropTypes.func,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onFitDrawingToViewport: PropTypes.func.isRequired,
  onToggleNonWorkAreaElements: PropTypes.func.isRequired
};

export default DxfViewport;
